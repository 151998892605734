import { Typography } from '@material-ui/core';
import styles from './UsersleftMessage.module.scss';

type Props = {
  usersLeftToSelect: number;
  usersLeftToPay: number;
};

export const UsersleftMessage: React.FC<Props> = ({
  usersLeftToSelect,
  usersLeftToPay,
}) => {
  let message = (
    <Typography>
      You have <strong>{usersLeftToSelect} users</strong> left to select and{' '}
      <strong>{usersLeftToPay} users</strong> left to pay. Please complete these
      steps to add your user to the list.
    </Typography>
  );

  if (usersLeftToSelect === 0) {
    message = (
      <Typography>
        You have <strong>{usersLeftToPay} users</strong> left to pay. Please
        complete these steps to add your user to the list.
      </Typography>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.root}>{message}</div>
    </div>
  );
};
