import { useContext } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import AuthContext from 'contexts/AuthContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },

  text: {
    maxWidth: '1166px',
    width: '100%',
    '& a': {
      color: '#0fa5df',
      textDecoration: 'none',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
    '& .h2': {
      margin: 0,
      marginBottom: '1%',
    },
    '& .h3': {
      margin: 0,
      marginBottom: '1%',
    },
    '& p': {
      margin: 0,
      marginBottom: '1%',
    },
    '& .center': {
      textAlign: 'center',
    },
  },
});

export const ReferralLink = () => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  if (!user) {
    return null;
  }

  const userLink = `${window.location.origin}/${encodeURIComponent(
    user.name,
  )}/`;

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        Share your affiliate link below to build your referral list
        <br />
        <a href={userLink} rel="noreferrer" target="_blank">
          {userLink}
        </a>
      </Typography>
    </div>
  );
};
